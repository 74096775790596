<template>
    <div class="bookdetail">
        <div class="top">
            <div style="font-weight: bold;font-size: 25px;margin-left: 0px">{{BookDetail.bookName }}</div>
            <!-- <div style="font-weight: bold; font-size: 23px;">×</div> -->
        </div>
        <el-tabs v-if="isShow" v-model="activeName" type="border-card" style="margin-bottom: 40px;">
            <el-tab-pane label="图书信息" name="first">
                <div class="item">
                    <div class="left">
                        <div>封面：</div>
                        <img style="width: 225px; height: 300px;" :src="BookDetail.img">
                    </div>
                    <div class="right">
                        <div class="Txt">
                            <div class="txt">沐心码：</div>
                            <div class="txt1"> {{ BookDetail.letuCode }}</div>
                        </div>
                        <div class="Txt">
                            <div class="txt">ISBN：</div>
                            <div class="txt1">{{ BookDetail.isbn }}</div>
                        </div>
                        <div class="Txt">
                            <div class="txt">书名：</div>
                            <div class="txt1">{{ BookDetail.bookName }}</div>
                        </div>
                        <div class="Txt">
                            <div class="txt">作者：</div>
                            <div class="txt1">{{ BookDetail.author }}</div>
                        </div>
                        <div class="Txt">
                            <div class="txt">副标题：</div>
                            <div class="txt1">{{ BookDetail.subtitle }}</div>
                        </div>
                        <div class="Txt">
                            <div class="txt">翻译者：</div>
                            <div class="txt1">{{ BookDetail.interpreter }}</div>
                        </div>
                        <div class="Txt">
                            <div class="txt">系列情况：</div>
                            <div class="txt1">{{ BookDetail.seriesSituation == 0 ? "单本书" : "丛书系类" }}</div>
                        </div>
                        <div class="Txt">
                            <div class="txt">出版社：</div>
                            <div class="txt1">{{ BookDetail.publisher }}</div>
                        </div>
                        <div class="Txt">
                            <div class="txt">出版时间：</div>
                            <div class="txt1">{{ BookDetail.publishDate }}</div>
                        </div>
                        <div class="Txt">
                            <div class="txt">页数：</div>
                            <div class="txt1">{{ BookDetail.pages }}</div>
                        </div>
                        <div class="Txt">
                            <div class="txt">中图法分类：</div>
                            <div class="txt1">{{ BookDetail.clc }}</div>
                        </div>
                        <div class="Txt">
                            <div class="txt">中图法分类号：</div>
                            <div class="txt1">{{ BookDetail.clcNumber }}</div>
                        </div>
                        <div class="Txt">
                            <div class="txt">著者号：</div>
                            <div class="txt1">{{ BookDetail.authorNumber }}</div>
                        </div>
                        <div class="Txt">
                            <div class="txt">所属分馆：</div>
                            <div class="txt1">{{ BookDetail.bid }}</div>
                        </div>
                        <div class="Txt">
                            <div class="txt">所在分馆：</div>
                            <div class="txt1">{{ BookDetail.branchName }}</div>
                        </div>
                        <div class="Txt">
                            <div class="txt">能否外借：</div>
                            <div class="txt1">{{ BookDetail.canBorrow == 0 ? '可外借' : '不可外借' }}</div>
                        </div>
                        <div class="Txt">
                            <div class="txt">图书状态：</div>
                            <div class="txt1">{{ BookDetail.status }}</div>
                        </div>
                        <div class="Txt">
                            <div class="txt">入库时间：</div>
                            <div class="txt1">{{ BookDetail.createTime }}</div>
                        </div>
                        <div class="Txt">
                            <div class="txt">图书来源：</div>
                            <div class="txt1">{{ BookDetail.donorsName }}</div>
                        </div>
                        <div class="Txt">
                            <div class="txt">备注：</div>
                            <div class="txt1">{{ BookDetail.remark }}</div>
                        </div>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="借阅历史" name="second">
                <!-- 图标 -->
                <div class="top1">
                    <div style="display: flex;">
                        <div class="circle" style="background: #f0f9eb;"></div>
                        <div class="txt">在借记录</div>
                    </div>
                    <div style="display: flex;">
                        <div class="circle" style="background: oldlace;"></div>
                        <div class="txt">超期记录</div>
                    </div>
                </div>
                <!-- 表格 -->
                <el-table :data="tableData" style="width: 100%" :row-class-name="tableRowClassName">
                    <el-table-column prop="username" label="读者">
                    </el-table-column>
                    <el-table-column prop="lendTime" label="借出时间">
                    </el-table-column>
                    <el-table-column prop="returnTime" label="归还时间">
                    </el-table-column>
                    <el-table-column prop="expireTime" label="到期时间">
                    </el-table-column>
                    <el-table-column prop="lendBranch" label="借出馆" width="180">
                    </el-table-column>
                    <el-table-column prop="returnBranch" label="归还馆" width="180">
                    </el-table-column>
                </el-table>
            </el-tab-pane>
        </el-tabs>

        <el-form v-else ref="BookForm" :model="BookForm" :rules="BookFormRules" label-width="110px">
            <div style="font-weight: bold;font-size: 25px;margin-left: 0px;">&nbsp;&nbsp;编辑图书</div>

            <div style="display: flex;margin-top: 15px;padding: 15px;width: 100%;flex: 1;">
                <div >
                    <div>封面：</div>
                    <!-- <el-upload action="https://jsonplaceholder.typicode.com/posts/" list-type="picture-card">
                        <i class="el-icon-plus"></i>
                    </el-upload> -->

                    <el-upload class="avatar-uploader" action="https://api.leshop.cloud/book/uploadImage"
                        name="image"
                        :headers="headers"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess" 
                        :before-upload="beforeAvatarUpload">
                        <img v-if="BookForm.img" :src="BookForm.img" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        <div style="margin-top: -50px;font-size: 14px;color: #858585;">拖拽 / 点击上传封面</div>
                        <div style="margin-top: 80px;"></div>
                    </el-upload>

                </div>
                <div style="margin-left: 20px;">
                    <el-form-item label="沐心码：" prop="letuCode">
                        <el-input v-model="BookForm.letuCode"></el-input>
                    </el-form-item>
                    <el-form-item label="ISBN码：" prop="isbn">
                        <el-input v-model="BookForm.isbn"></el-input>
                    </el-form-item>
                    <div style="display: grid;grid-template-columns: 1fr 1fr;">
                        <div>
                            <el-form-item label="书名：" prop="bookName">
                                <el-input v-model="BookForm.bookName"></el-input>
                            </el-form-item>
                            <el-form-item label="副标题" prop="subtitle">
                                <el-input v-model="BookForm.subtitle"></el-input>
                            </el-form-item>
                            <el-form-item label="系列情况" prop="seriesSituation">
                                <!-- <el-radio-group v-model="BookFormRules.seriesSituation">
                                    <el-radio label="单本书"></el-radio>
                                    <el-radio label="丛书"></el-radio>
                                </el-radio-group> -->
                                <el-radio v-model="BookForm.seriesSituation" label="0" @input="openSeries">单本书</el-radio>
                                <el-radio v-model="BookForm.seriesSituation" label="1" @input="openSeries">丛书</el-radio>
                                <el-input v-model="BookForm.series" placeholder="丛书名字" v-if="openSeriesCode"></el-input>
                            </el-form-item>
                            <!-- <el-form-item label="丛书名" prop="series">
                                <el-input v-model="BookForm.series"></el-input>
                            </el-form-item> -->
                            <el-form-item label="出版社" prop="publisher">
                                <el-input v-model="BookForm.publisher"></el-input>
                            </el-form-item>
                            <el-form-item label="页数" prop="pages">
                                <el-input v-model="BookForm.pages"></el-input>
                            </el-form-item>
                        </div>
                        <div>
                            <el-form-item label="作者：" prop="author">
                                <el-input v-model="BookForm.author"></el-input>
                            </el-form-item>
                            <el-form-item label="译者：" prop="interpreter">
                                <el-input v-model="BookForm.interpreter"></el-input>
                            </el-form-item>
                            <el-form-item label="中图法分类号：" prop="clcNumber">
                                <el-input v-model="BookForm.clcNumber"></el-input>
                            </el-form-item>
                            <el-form-item label="出版时间：" prop="name">
                                <el-input v-model="BookForm.publishDate"></el-input>
                            </el-form-item>
                            <el-form-item label="价格：" prop="name">
                                <el-input v-model="BookForm.publishDate"></el-input>
                            </el-form-item>
                        </div>
                    </div>
                    <el-divider></el-divider>
                    <div style="display: flex;flex-direction: column;">
                        <el-form-item label="能否外借：" prop="canBorrow">
                            <el-radio-group v-model="BookForm.canBorrow">
                                <el-radio label="0" >可外借</el-radio>
                                <el-radio label="1">不可外借</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="自定义书号：" prop="isbn">
                            <el-input v-model="BookForm.customizeNumber"></el-input>
                        </el-form-item>
                        <el-form-item label="来源(捐赠人姓名)：" prop="isbn">
                            <el-input v-model="BookForm.donorsName"></el-input>
                        </el-form-item>
                        <el-form-item label="备注：" prop="remark">
                            <el-input type="textarea" v-model="BookForm.remark"></el-input>
                        </el-form-item>
                    </div>
                </div>
            </div>
        </el-form>
        <div class="bottom" v-if="isShow">
            <el-button class="btn" type="primary" @click="editBook">编辑图书</el-button>
            <!-- <el-button class="btn" type="primary">调馆</el-button> -->
            <el-button class="btn" @click="backPage">返回</el-button>
        </div>

        <div class="bottom" v-else>
            <el-button class="btn" type="success" @click="editConfirm">确认编辑</el-button>
            <el-button class="btn" @click="backReturn">返回</el-button>
        </div>
    </div>
</template>

<script>
import { findOneBook, updateBook } from "@/api/book"
import { FindLendRecord } from '@/api/lendRecord'
import {getToken} from '@/utils/auth.js'
import  "@/utils/dateformat.js";
export default {
    data() {
        return {
            // 丛书转换
            
            // 丛书显示
             openSeriesCode:false,
            //上传图片请求头
            headers:{
                         "Authorization": getToken() //"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJyb2xlIjoiYWRtaW4iLCJiaWQiOjEsImFpZCI6MSwiYWNjb3VudCI6ImxldHUxMjM0IiwidXNlcm5hbWUiOiLnqIvmoLnno4ogICJ9.AkDHUs_6NXASjfwNqc1G9pB-eCO5ZFiSoSQK_5WBLeU"
                    },
            // 编辑时显示
            isShow: true,
            letuCodeNum:0,
            activeName: "first",
            BookDetail: [],
            tableData: [],
            BookForm: {},
            BookFormRules: {
                bookName: [{ required: true, message: '请输入书名', trigger: 'blur' }],
                canBorrow: [{ required: true, message: '请选择是否能外借', trigger: 'change' }],
                donorsName: [{ required: true, message: '请选择活动区域', trigger: 'change' }],
                id: [{ required: true, message: '请选择活动区域', trigger: 'change' }],
                isbn: [{ required: true, message: '请选择活动区域', trigger: 'change' }],
                letuCode: [{ required: true, message: '请选择活动区域', trigger: 'change' }]
            }
        };
    },
    created() {
        this.letuCodeNum = this.$route.query.letuCode;
        
        this.letuCode = this.$route.query.letuCode;
        console.log(this.letuCode+"========");
        this.isShow = true
    },
    mounted() {
        

        this.getDetail(this.letuCodeNum);
        this.getLendRecordList()
        
    },
    watch: {
        letuCode(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.getDetail(newVal)
            }
        }
    },
    methods: {

       
        // 获得该书详情
         getDetail(letuCodeBak) {
            let queryParams = {letuCode:letuCodeBak};
            console.log(queryParams);
             findOneBook(queryParams).then(({ data }) => {
                this.BookDetail = data;
                // this.BookDetail.map((item)=>{
                    // if(item.seriesSituation == "单本书"){
                    //     item.seriesSituation = 0
                    // }else{
                    //     item.seriesSituation = 1
                    // }
                // })
                this.BookForm = data;
                this.BookForm.canBorrow == '可外借' ? this.BookForm.canBorrow = '0' : this.BookForm.canBorrow = '1'
                this.BookForm.seriesSituation == null ? this.BookForm.seriesSituation = '0' : this.BookForm.seriesSituation = '1'

                console.log(this.BookForm)
                
                    // if(this.BookForm.canBorrow == ''){
                    //     BookForm.seriesSituation = "0"
                    // }else{
                    //     BookForm.seriesSituation = "1"
                    // }
                
            })
        },
        tableRowClassName({ row, rowIndex }) {
            //console.log("换色记录")
            const nowDate = new Date().format("yyyy-MM-dd hh:mm:ss")
            console.log(nowDate)
            if((row.returnTime == null && nowDate > row.expireTime) || (row.returnTime > row.expireTime) ){
                            return 'warning-row';
                        }else if( row.returnTime == null && nowDate < row.expireTime) {
                            //再借记录
                            return 'success-row';
                        }

                       
            // if (rowIndex === 1) {
            //     return 'warning-row';
            // } else if (rowIndex === 3) {
            //     return 'success-row';
            // }
            // return '';
        },
        // 获得该书的借阅记录
        getLendRecordList() {
            //console.log(this.letuCode)
            let queryParams = { letuCode: this.letuCode }
            FindLendRecord(queryParams).then(({ data }) => {
                this.tableData = data.lendRecordData;
                // console.log(this.tableData)
            })
        },
        // 选择编辑
        editBook() {
            this.isShow = !this.isShow;
        },
        // 确认编辑
        editConfirm() {
            console.log(this.BookForm)
        //this.BookForm.canBorrow == "可外借" ? this.BookForm.canBorrow = '0' : this.BookForm.canBorrow = '1'
            let queryParams = this.BookForm;

            console.log(queryParams);
            updateBook(queryParams).then(res => {
                console.log(res)
                if (res.code == 200 && res.message == 'success') {
                    alert("修改成功")
                    this.isShow = !this.isShow
                }else{
                    //console.log(res)
                    alert(res.message)
                }
            })
        },
        //返回上一页
        backPage() {
            // this.BookDetail = [],
            // this.tableData = [],
            //this.BookForm = {},
            this.$router.go(-1)

        },
        // 退出编辑
        backReturn() {
            this.isShow = !this.isShow
        },
        //图书图片上传方法
        handleAvatarSuccess(res, file) {
            this.BookForm.img = URL.createObjectURL(file.raw);
            console.log(res)
            res != null ? this.BookForm.img = res.data : this.BookForm.img ='';
        },
        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg' || 'image/jpg';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
            this.$message.error('上传头像图片只能是 JPG 格式!');
            }
            if (!isLt2M) {
            this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isJPG && isLt2M;
        },
        //打开丛书按钮
        openSeries(label){
            if(label == 1){
            this.openSeriesCode=true
            
            }else{
            this.openSeriesCode=false
            }
        },
    },
};
</script>

<style >
  .warning-row {
    background: oldlace;
  }

   .success-row {
    background: #f0f9eb;
  }


.bookdetail {
    position: relative;
    border: 1px solid #DCDFE6;
    background-color: #FFF;
    border-radius: 5px;
}

.top {
    padding: 15px;
    display: flex;
    justify-content: space-between;
}

.bottom {
    position: fixed;
    /* top: 0; */
    bottom: 0;
    padding: 15px 30px;
    border: 1px solid #DCDFE6;
    background-color: #FFF;
    border-radius: 5px;
    width: 77.6%;
}

.item {
    display: flex;
}

.left {
    display: flex;
}

.right {
    margin-top: 20px;
    margin-left: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
    grid-column-gap: 200px
}

.include {
    display: flex;
    justify-content: space-between;
    flex: 1;
    margin: 20px 0;
}

.Txt {
    display: flex;
    text-align: right;
    margin-left: 20px;
}

.txt {
    color: #858585;
    line-height: 1.5;
    min-width: 110px;
    font-size: 13px;
}

.txt1 {
    color: #000;
    line-height: 1.5;
    font-size: 13px;
    margin-left: 15px;
}

.top1 {
    display: flex;
}

.top1 .circle {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-top: 10px;
}

.top1 .txt {
    margin: 10px 0 10px 15px;
}

.el-table .warning-row {
    background: oldlace;
}


.bookdetail .warning-row {
    background: oldlace;
}

.bookdetail .el-table .success-row {
    background: #f0f9eb;
}
</style>